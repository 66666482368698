.dmn-js-parent {
  .view-drd {
    display: none;
  }

  .bjs-powered-by {
    display: none !important;
  }

  .dmn-definitions {
    background-color: white !important;
    border: 2px solid var(--color-prism-stroke-secondary) !important;
    border-radius: var(--radius-prism-radius-100) !important;
    padding: 0 !important;
    .dmn-definitions-name {
      background-color: var(--color-prism-surface-secondary) !important;
      border-radius: var(--radius-prism-radius-100)
        var(--radius-prism-radius-100) 0 0 !important;
      border-bottom: 2px solid var(--color-prism-stroke-secondary) !important;
      padding: var(--spacing-prism-space-100) !important;
    }
    .dmn-definitions-id {
      padding: var(--spacing-prism-space-100) !important;
    }
  }

  .dmn-definitions > .dmn-definitions-name:hover,
  .dmn-definitions > .dmn-definitions-name:focus {
    border: none !important;
    border-bottom: 2px solid var(--color-prism-stroke-secondary) !important;
  }

  .dmn-definitions > .dmn-definitions-id:hover,
  .dmn-definitions > .dmn-definitions-id:focus {
    border: none !important;
    border-radius: var(--radius-prism-radius-100) !important;
  }

  .djs-palette {
    background-color: var(--color-prism-surface-secondary) !important;
    border: none !important;
    border-radius: var(--radius-prism-radius-100) !important;
    .entry:hover {
      cursor: pointer !important;
      color: var(--color-prism-content-state-action-default) !important;
    }
  }

  .djs-overlay {
    display: none !important;
  }

  .djs-visual {
    rect,
    path,
    polyline {
      stroke: var(--color-prism-stroke-secondary) !important;
    }
  }

  marker {
    stroke: var(--color-prism-stroke-secondary) !important;
    fill: var(--color-prism-stroke-secondary) !important;
  }

  .context-menu {
    background-color: var(--color-prism-surface-default) !important;
    border-radius: var(--radius-prism-radius-100) !important;
    border: 2px solid var(--color-prism-stroke-secondary) !important;
    .context-menu-group-entry:hover {
      background-color: var(--color-prism-surface-secondary) !important;
      cursor: pointer !important;
    }
  }

  .dpp-properties-panel {
    border-radius: var(--radius-prism-radius-100) !important;
  }

  .decision-table-properties {
    border: 1px solid var(--color-prism-stroke-secondary) !important;
    border-radius: var(--radius-prism-radius-100) var(--radius-prism-radius-100)
      0 0 !important;
    border-bottom: none !important;
    margin: 10px 10px 0px 10px !important;
    background-color: var(--color-prism-surface-secondary) !important;
    font-weight: bold !important;
    .decision-table-header-separator {
      border: 1px solid var(--color-prism-stroke-primary) !important;
    }
  }

  .tjs-table-container {
    background-color: var(--color-prism-surface-default) !important;
    border-radius: 0 0 0 0 !important;
    margin: 0px 10px 0px 10px !important;
    border: none !important;
  }

  .input-cell,
  .rule-index,
  .index-column,
  .output-cell,
  .annotation,
  .add-rule-add {
    border: 1px solid var(--color-prism-stroke-secondary) !important;
  }

  .add-rule-add {
    border-radius: 0 0 0 var(--radius-prism-radius-100) !important;
  }

  .add-rule.annotation {
    border-radius: 0 0 var(--radius-prism-radius-100) 0 !important;
  }

  .bio-properties-panel-container {
    background-color: var(--color-prism-surface-default) !important;
    .bio-properties-panel-group-header {
      cursor: pointer;
    }
    .bio-properties-panel-group {
      border-bottom: 1px solid var(--color-prism-stroke-secondary) !important;
    }
  }

  .bio-properties-panel-header {
    background-color: var(--color-prism-surface-secondary) !important;
    border-bottom: 1px solid var(--color-prism-stroke-secondary) !important;
    .bio-properties-panel-header-icon {
      fill: var(--color-prism-surface-state-action-default) !important;
    }
  }

  .dmn-icon-plus {
    background-color: var(
      --color-prism-surface-state-action-default
    ) !important;
    color: white !important;
  }

  .dmn-icon-plus:hover {
    cursor: pointer;
  }

  .dms-select-options {
    background-color: var(--color-prism-surface-default) !important;
    .option:hover {
      background-color: var(--color-prism-surface-secondary) !important;
    }
    .option.active {
      color: var(--color-prism-content-state-action-default) !important;
    }
  }
}
