@tailwind base;
@tailwind components;
@tailwind utilities;

.borderTopRadius {
  border-radius: 12px 12px 0px 0px;
}

.borderBottomRadius {
  border-radius: 0px 0px 12px 12px;
}

.caseSummary {
  display: grid;
  grid-template-columns: 28% auto;
  gap: 1rem;
}

.borderSize {
  border-width: 6px;
}

.twofifty {
  width: 250px;
}
.spinner {
  border: 4px solid lightgray;
  border-bottom: 4px solid #2969ff;
}

.smallSpinner {
  border: 2px solid transparent;
  border-bottom: 2px solid white;
}

.tabs {
  border-bottom: 2px solid #2969ff;
}

.inactiveTabs {
  border-bottom: 2px solid transparent;
}

.blackToTransparentGradient {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

@keyframes jonV {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.scaleIn {
  animation: jonV 1000ms ease-in-out 1;
}

@keyframes glowX {
  0% {
    transform: translateX(0%);
  }
  40% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(100%);
  }
  90% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes glowY {
  0% {
    transform: translateY(0%);
  }
  40% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(100%);
  }
  90% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

.showOnHover:hover {
  overflow-x: visible;
}

.dateIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 10px;
  color: black;
  width: 1rem;
  height: 1rem;
  z-index: 2;
  pointer-events: none;
}

.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-fullWidth,
.MuiInputBase-formControl,
.MuiInputBase-adornedEnd {
  border: 0px;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
}

.overCreditBlue {
  background: repeating-linear-gradient(
    45deg,
    rgb(179, 189, 255) 3px,
    rgb(179, 189, 255) 15px,
    #1b4df5 5px,
    #1b4df5 20px
  );
}

.overCreditRed {
  background: repeating-linear-gradient(
    45deg,
    #ffe2e1 5px,
    #ffe2e1 15px,
    #e4251b 5px,
    #e4251b 20px
  );
}

.onePixelHeight {
  height: 1px;
  width: 20px;
}
